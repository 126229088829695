<template>
  <v-main class="Main-wrapper">
    <v-container
      id="main-layout"
      fluid
      tag="section"
    >
      <router-view />
    </v-container>

    <!-- <dashboard-core-footer /> -->
  </v-main>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    // DashboardCoreFooter: () => import('./Footer'),
  },
};
</script>
<style lang="sass" scoped>
:deep .v-main__wrap
  background-color: #F2F4F7 !important
</style>
